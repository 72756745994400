.addBlogForm {
  margin: 20px;
}

.background-pic {
  background: url("./images/bloging.jpg") center center/cover no-repeat;
  object-fit: contain;
}

.head {
  padding: 5% 0% 5% 0%;
}

h1 {
  font-size: 3.5rem;
  color: white;
}

.blogswithbackground {
  background: url("https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGJsb2d8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60")
    center center/cover no-repeat;
  
}

a {
  color: black;
}


body {
  font-family: "Montserrat";
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat-Bold";
}
.container-fluid{
  padding: 7% 15% 3% 15%;

}

p {
  color: #8f8f8f;
}

/* Headings */

.big-heading {
  font-family: "Montserrat-Black";
  font-size: 3.5rem;
  line-height: 1.5;
  padding-bottom: 5%;
}
.colored-section {
  background-color: #fdcb9e;
  color: #fff;
}





/* Title Image */

.title-image {
  width: 50%;
  border-radius: 5%;
  position: absolute;
  right: 25%;
  left: 25%;
}

@media (max-width: 1028px) {

  #title {
    text-align: center;
  }

  .title-image {
    position: static;
  }
}
